import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/WiFi/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DangerBox1 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox2';
import DangerBox3 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox3';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Network/WiFi/PrimaryBox';
import WiFiTable from 'components/Web_User_Interface/1440p_Series/Network/WiFi/wifiTable';
import WiFiAdHocTable from 'components/Web_User_Interface/1440p_Series/Network/WiFi/wifiAdHocTable';
import YoutubeVideoCard from 'components/Web_User_Interface/1440p_Series/Network/WiFi/YoutubeVideoCard';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // WiFi",
  "path": "/Web_User_Interface/1440p_Series/Network/WiFi/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.",
  "image": "./WebUI_1440p_SearchThumb_Network_WiFi.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Network_WiFi.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // WiFi' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.' image='/images/Search/WebUI_1440p_SearchThumb_Network_WiFi.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Network_WiFi.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/WLAN/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/WiFi/' crumbLabel="WiFi" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4270a48ccb328cb1ad9c2b11501813ec/121b3/1440p_Settings_Network_WLAN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACVklEQVQoz1WR205TYRCFeQsT9IaYQEspNUJbiELZ+/93N/vQ7vOhtCUgcii0VIgYuUQJR6OYeKVvIHhpjE/3GQpcePFlJpnJZK01Q/l8nmxmDLl2gnv0B/PwF8b7G7SDa8Tba+TBLTf39Q5xcI3+7ifR8V/83iVPhh8xPPyYkZERhsbHc2QyY+hJh/TNBdHOR9L+KWn/nGT3jLh3SrBzTNS7628JuyeEO8c09y+xGh0m8wWeFabIT0wylMvlyGYzSCkJkxZh2mJlbZO1jW1erXdYfb1FvLSM73sEcTqYB3EDL0zxogaWZaMqElXRKRZn7g+OjSKdJk73M9bWKbXOGV73Amf7glrnnNrOJe7KPn73jGjvimT/injvC17/K15zFU19SWVeMj1VflCYRQoVt25jm4vULANdEwNqtoldlTimgW2bWEYVoVQGu/3dHivLAVU5x/ycoFS6V5jJZKhWq7RabdI0JYpj/CDA9wPiNCUJQgLHxTAtVCFQFBUhBPqigWPrSKWCVBRmy+Xbp4wPMlSFxPEC6o6L53n/43u4rotlmmhSHbgRmsa8qmIZEk2poAuFUrH0oDCLpQvWmx7NyMF17w484Lgu9XqdRcNEszyqloerVakrgrZnYWsLLMwrTE+XGJqYyPF0NMt2tMDvoxqXXRvPD4nCkDiKSOKIRpKwlMY0VzZoH36ncfCNT37KDzfiQ8un01ykUhGUbzO8s5wlly8wXphiovCcUqnM7Mws+sILanKOJUdns53Q723Sjl0cUyeqW6xtrZMYAl+bpVQsks9P8g+QEYMxHeaenQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4270a48ccb328cb1ad9c2b11501813ec/e4706/1440p_Settings_Network_WLAN.avif 230w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/d1af7/1440p_Settings_Network_WLAN.avif 460w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/7f308/1440p_Settings_Network_WLAN.avif 920w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/07541/1440p_Settings_Network_WLAN.avif 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4270a48ccb328cb1ad9c2b11501813ec/a0b58/1440p_Settings_Network_WLAN.webp 230w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/bc10c/1440p_Settings_Network_WLAN.webp 460w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/966d8/1440p_Settings_Network_WLAN.webp 920w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/893d8/1440p_Settings_Network_WLAN.webp 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4270a48ccb328cb1ad9c2b11501813ec/81c8e/1440p_Settings_Network_WLAN.png 230w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/08a84/1440p_Settings_Network_WLAN.png 460w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/c0255/1440p_Settings_Network_WLAN.png 920w", "/en/static/4270a48ccb328cb1ad9c2b11501813ec/121b3/1440p_Settings_Network_WLAN.png 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4270a48ccb328cb1ad9c2b11501813ec/c0255/1440p_Settings_Network_WLAN.png",
              "alt": "Web User Interface - 1440p Series - Network WiFi",
              "title": "Web User Interface - 1440p Series - Network WiFi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox1 mdxType="DangerBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <WiFiTable mdxType="WiFiTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCard mdxType="YoutubeVideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "what-is-ad-hoc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-ad-hoc",
        "aria-label": "what is ad hoc permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is Ad-Hoc?`}</h3>
    <p>{`In contrast to Infrastructure mode, where the camera connects to your home WiFi network, in Ad-Hoc Mode, the camera creates its own WiFi Access Point. Clients, such as smartphones, can directly connect to the camera's Ad-Hoc WiFi network and access the camera using the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.9.1`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a070bf77d8ad7830c98ac82ddb799d11/121b3/1440p_Settings_Network_AdHoc_WLAN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACN0lEQVQoz1WSXWvTcBTG+y2E6c0QXJu2q7g1XXFrU5MmaZM0afL/56XdxnCMTcc2J1bcnQMR9iJ64Y1+A90uRfx2P0k2B178eDiHw8N5OKdUr9eplBcwtj/iv//N8OSawbtr+rMr9Dc/MXJmV4Xqd1xhvf2B/PCH8OCcB3P3mJu7z/z8PCVFqVIuL2Cl+6SvPxMfnpEdX5K8uiTOObpAHJwVmh5/KhCH58jDM6azL7iTfRZrDR43lqjXFilVq1UqioKurTJ2Tfyhwdi1CL0b8p4MhmykIZtZVJCJEYFjMhroDK0+z3o5Js3mSr6hQk6nq2FaNrrRx7IGDB33rjb6Jo7rYQ+GRd8PxngjH3vgMPJsTEOj29FZXmrdblip0Ov18DwPy7IKHMfBdV1s2y5q3/fJsqxACIEQEVEkiaWLbXZYW9VR1RVKVUWhXC5jmmYxHMfxfyRJUmhuMplM7mZyQyljorGDrnUwej3ardZN5NwwjyXilEhI4lgWw/9MpZSF4XQ6JU1ipIgIhSCUMWHgYGgdLKOH2lRvIpfLFVzbYHdTsJGEhFEeJyo0DHNCgiBAxgki2yJMN1gPI9b9Mc+lj9PvonV7LC+rlGq1Kg8fVXgpNX6delzsu4xDgRSCWAoSKUmThEkqmWztsH7yjWz2lctxwndfcDoNeTG16XZ1WurtlfOjVOsNlPyXGk9Q1RbtlTaW9hTPWGPiW+xuJBwd7LEZBwSORew7bO/tkAx0wn4btdmkXl/kL7HTf/VOz48TAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a070bf77d8ad7830c98ac82ddb799d11/e4706/1440p_Settings_Network_AdHoc_WLAN.avif 230w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/d1af7/1440p_Settings_Network_AdHoc_WLAN.avif 460w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/7f308/1440p_Settings_Network_AdHoc_WLAN.avif 920w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/07541/1440p_Settings_Network_AdHoc_WLAN.avif 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a070bf77d8ad7830c98ac82ddb799d11/a0b58/1440p_Settings_Network_AdHoc_WLAN.webp 230w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/bc10c/1440p_Settings_Network_AdHoc_WLAN.webp 460w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/966d8/1440p_Settings_Network_AdHoc_WLAN.webp 920w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/893d8/1440p_Settings_Network_AdHoc_WLAN.webp 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a070bf77d8ad7830c98ac82ddb799d11/81c8e/1440p_Settings_Network_AdHoc_WLAN.png 230w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/08a84/1440p_Settings_Network_AdHoc_WLAN.png 460w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/c0255/1440p_Settings_Network_AdHoc_WLAN.png 920w", "/en/static/a070bf77d8ad7830c98ac82ddb799d11/121b3/1440p_Settings_Network_AdHoc_WLAN.png 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a070bf77d8ad7830c98ac82ddb799d11/c0255/1440p_Settings_Network_AdHoc_WLAN.png",
              "alt": "Web User Interface - 1440p Series - Network WiFi",
              "title": "Web User Interface - 1440p Series - Network WiFi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <WiFiAdHocTable mdxType="WiFiAdHocTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "camera-reset--wps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-reset--wps",
        "aria-label": "camera reset  wps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Reset & WPS`}</h2>
    <p>{`If you are no longer able to access your cameras web user interface you can press the reset button on your camera. You will see the Status LEDs turn off and...`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`After 3s`}</strong>{`: Turn the Status LEDs on and start the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/WiFi/"
        }}>{`Wireless-Protected-Setup`}</a>{` or `}<strong parentName="li">{`WPS`}</strong>{` mode that allows you to add your camera to your local WiFi. Press the button again for `}<strong parentName="li">{`2s`}</strong>{` to quit the mode before the 2 minute timeout.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 15s`}</strong>{`: Let the Status LEDs flash slowly and after releasing the button reset all your camera settings `}<strong parentName="li">{`except the network configuration`}</strong>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 20s`}</strong>{`: Let the Status LEDs flash fast and after releasing the button reset `}<strong parentName="li">{`ALL`}</strong>{` your camera settings.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 30s`}</strong>{`: Turn the Status LEDs off and roll back your camera to the state it had before the last Firmware update and restart your camera.`}</li>
    </ol>
    <p>{`If you cannot use the WPS service (Wireless Protected Setup) to integrate the camera into your local WiFi network - see our `}<a href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">{`Installation Wizard`}</a>{` for further information for the WPS function - click search to start the WLAN scanner. Just choose your own network, add your WiFi password and click “Test”. Please disconnect the LAN cable and wait. The camera will automatically - within `}<strong parentName="p">{`max. 5 minutes`}</strong>{` - connect to your WiFi network. The cameras WiFi module will receive a new address from your router - in case you didn’t assign a `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/IP_Configuration/"
      }}>{`Static IP Address`}</a>{` to your camera yet. You might have to use the `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
      }}>{`INSTAR Camera Tool`}</a>{` again to rediscover your camera. Your camera is now connected via WiFi!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox3 mdxType="DangerBox3" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox2 mdxType="DangerBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      